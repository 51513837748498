<template>
  <div>
    <Modal :data="deleteButtonState" v-on:action="deleteDirectoryType" />
    <h2 class="mt-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Toutes les catégories d'annuaires
    </h2>
    <h5 class="mb-6 text-sm font-semibold text-gray-700 dark:text-gray-200">
      Pour rappel: seules les catégories d'annuaires ayant 0 utilisations
      peuvent être supprimées
    </h5>
    <div
      v-if="directoryTypes"
      class="w-full mb-8 overflow-hidden rounded-lg shadow-xs"
    >
      <div class="w-full overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <thead>
            <tr
              class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
            >
              <th class="px-4 py-3">Nom</th>
              <th class="px-4 py-3">Utilisé x fois</th>
              <th class="px-4 py-3">Actions</th>
            </tr>
          </thead>
          <tbody
            class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
          >
            <tr
              v-for="(dirType, index) in directoryTypes"
              :key="index"
              class="text-gray-700 dark:text-gray-400"
            >
              <td
                v-if="onEdit && onEdit.index === index"
                class="px-4 py-3 text-sm"
              >
                <input
                  class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                  v-model="onEdit.fr_content"
                />
              </td>
              <td v-else class="px-4 py-3 text-sm">
                {{ dirType.fr_content }}
              </td>
              <td class="px-4 py-3 text-sm">
                {{ dirType.count }}
              </td>
              <td class="px-4 py-3">
                <div class="flex items-center space-x-4 text-sm">
                  <button
                    class="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-purple-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                    aria-label="Modifier"
                    @click="editMode(index)"
                  >
                    <svg
                      class="w-5 h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                      ></path>
                    </svg>
                  </button>
                  <button
                    v-if="dirType.count <= 0"
                    class="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-purple-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                    aria-label="Supprimer"
                    @click="openDeleteModal(index)"
                  >
                    <svg
                      class="w-5 h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                  <button
                    v-if="onEdit && onEdit.index === index"
                    class="px-3 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-md active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                    @click="saveUpdate(index)"
                  >
                    Sauvegarder
                  </button>
                  <button
                    v-if="onEdit && onEdit.index === index"
                    class="px-3 py-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-md active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
                    @click="exitEditMode"
                  >
                    Annuler
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Créer un nouvelle catégorie d'annuaire
    </h2>
    <form
      class="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800"
      :onsubmit="postNew"
    >
      <label class="block text-sm">
        <span class="text-gray-700 dark:text-gray-400">Nom (français)</span>
        <input
          class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
          placeholder="Évènementiel"
          v-model="newDirectoryType"
        />
      </label>
      <button
        class="mt-4 px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
        @click="postNew"
      >
        Poster
      </button>
    </form>
  </div>
</template>

<script>
import directoryTypesApi from "@/utils/api/directory_types";
import Modal from "@/components/Modal";

export default {
  components: {
    Modal
  },
  data() {
    return {
      directoryTypes: null,
      newDirectoryType: null,
      onEdit: {
        index: null,
        id: null,
        fr_content: null
      },
      deleteButtonState: {
        index: null,
        open: false,
        title: "",
        description: null
      }
    };
  },
  mounted() {
    directoryTypesApi.getAll().then(res => {
      this.$data.directoryTypes = res.data;
    });
  },
  methods: {
    /**
     * Enter in edit mode
     */
    editMode: function(index) {
      this.$data.onEdit = {
        index: index,
        id: this.$data.directoryTypes[index].id,
        fr_content: this.$data.directoryTypes[index].fr_content
      };
    },

    /**
     * Exit edit mode.
     */
    exitEditMode: function() {
      this.$data.onEdit = null;
    },

    /**
     * Save directory type update.
     */
    saveUpdate: function(index) {
      if (this.$data.onEdit) {
        const directoryTypeToUpdate = this.directoryTypes[index];
        // call API
        directoryTypesApi
          .update(directoryTypeToUpdate.id, this.$data.onEdit.fr_content)
          .then(() => {
            this.directoryTypes[
              index
            ].fr_content = this.$data.onEdit.fr_content;
            this.$data.onEdit = null;
          });
      }
    },

    /**
     * Post new directory type.
     * @param e Event.
     */
    postNew: function(e) {
      e.preventDefault();

      // call API, push to directoryTypes and reset form
      directoryTypesApi.postNew(this.$data.newDirectoryType).then(res => {
        this.$data.directoryTypes.push({ ...res.data, count: 0 });
        this.$data.newDirectoryType = null;
      });
    },

    /**
     * Open modal for delete directory type.
     * @param index Index of directory type to update.
     */
    openDeleteModal: function(index) {
      this.deleteButtonState.index = index;
      this.deleteButtonState.description = `Souhaitez-vous réellement supprimer la catégorie "${this.directoryTypes[index].fr_content}" ?`;
      this.deleteButtonState.open = true;
    },

    /**
     * Delete directory type lol.
     */
    deleteDirectoryType: function() {
      directoryTypesApi
        .deleteById(this.directoryTypes[this.deleteButtonState.index].id)
        .then(() => {
          this.directoryTypes.splice(this.deleteButtonState.index, 1);
          this.deleteButtonState.open = false;
        });
    }
  }
};
</script>
