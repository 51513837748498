import { axios } from "@/utils/api/index";

/**
 * Get all directory types.
 * @return {Promise<AxiosResponse<any>>}
 */
export const getAll = () => {
  return axios().get("/directory-type/all");
};

/**
 * Post new directory type.
 * @param fr_content Directory type name (french).
 * @return {Promise<AxiosResponse<any>>}
 */
export const postNew = fr_content => {
  return axios().post("/directory-type/new", { fr_content: fr_content });
};

/**
 * Update directory type.
 * @param id Directory type to update ID.
 * @param fr_content New directory type name (french).
 * @return {Promise<AxiosResponse<any>>}
 */
export const update = (id, fr_content) => {
  return axios().patch("/directory-type/update/", {
    id: id,
    fr_content: fr_content
  });
};

/**
 * Delete directory type.
 * @param id Directory type to delete ID.
 * @return {Promise<AxiosResponse<any>>}
 */
export const deleteById = id => {
  return axios().delete("/directory-type/delete/" + id);
};

export default { getAll, postNew, update, deleteById };
